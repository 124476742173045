<template>
  <ciam-card-content>
    <div class="flex flex-row justify-between">
      <div class="mt-5 ml-5 mb-5">
        <div class="flex items-center">
          <div class="w-96">
            <ciam-input
              type="text"
              :debounceTimeout="1000"
              v-model="email"
              @input="clearError"
              @change-debounced="onEmailDebounced($event)"
              :placeholder="$t('backoffice.deployments.searchByEmail')"
            ></ciam-input>
          </div>
        </div>
      </div>
      <div class="mt-5 mr-5">
        <ciam-button 
          class="primary" 
          :loading="loading"
          @click="onSearchClick"
        >
          <ciam-icon name="fa-search"></ciam-icon>
        </ciam-button>
      </div>
    </div>

    <ciam-card-content-line type="full-width" v-if="problem">
      <template v-slot:content>
        <ciam-alert 
          :title="problem.title"
          :description="problem.detail"
          :type="AlertStatus.ALERT"
        ></ciam-alert>
      </template>
    </ciam-card-content-line>

    <b-table
      :loading="loading"
      :striped="true"
      :hoverable="true"
      :data="data"
      :total="totalElements"
      :per-page="size"
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      backend-sorting
      backend-pagination
      :aria-next-label="$t('nextPage')"
      :aria-previous-label="$t('previousPage')"
      :aria-page-label="$t('page')"
      :aria-current-label="$t('currentPage')"
      @page-change="onPageChange"
      @sort="onSort"
    >
      <b-table-column field="name" :label="$t('deployment.name')" sortable v-slot="props">
        <ciam-button
          :data-e2e="'deployment-name-' + props.row.name"
          :to="{ name: $routes.DeploymentDetails, params: { id: props.row.id } }"
        >{{ props.row.name }}</ciam-button>
        <status-badge :status="props.row.status" v-if="props.row.status !== 'RUNNING'" />
      </b-table-column>

      <b-table-column field="creation" :label="$t('deployment.creation')" sortable v-slot="props">
        {{ formatDate(props.row.creation) }}
      </b-table-column>

      <b-table-column field="pricingPlan.name" :label="$t('pricing.plan')" sortable v-slot="props">
        {{ props.row.pricingPlan && props.row.pricingPlan.name }}
      </b-table-column>

      <b-table-column field="pricingPlan.dedicatedInstance" :label="$t('deployment.instanceType')" sortable v-slot="props">
        {{ props.row.pricingPlan && props.row.pricingPlan.dedicatedInstance ? $t('deployment.dedicated') : $t('deployment.shared') }}
      </b-table-column>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p v-if="!email">{{ $t('backoffice.deployments.enterEmail') }}</p>
            <p v-else>{{ $t('backoffice.deployments.noResults') }}</p>
          </div>
        </section>
      </template>
    </b-table>

    <ciam-card-footer v-if="data.length > 0">
      <ciam-pagination
        :page-current="page"
        :page-count="totalPages"
        :result-count="totalElements"
        @change="onPageChange($event)"
      ></ciam-pagination>
    </ciam-card-footer>
  </ciam-card-content>
</template>

<script>
import CiamCardContent from '@/components/CiamCardContent';
import CiamInput from '@/components/CiamInput';
import CiamButton from '@/components/CiamButton';
import CiamIcon from '@/components/CiamIcon';
import CiamAlert, { AlertStatus } from '@/components/CiamAlert.vue';
import CiamCardFooter from '@/components/CiamCardFooter';
import CiamPagination from '@/components/CiamPagination';
import StatusBadge from '@/components/StatusBadge';
import { intOr } from '@/util/fp';
import BackofficeService from '@/pages/deployments/BackofficeService.js';
import { formatDate } from '@/util/date';

export default {
  name: 'BackofficeDeployments',
  components: {
    CiamCardContent,
    CiamInput,
    CiamButton,
    CiamIcon,
    CiamAlert,
    CiamCardFooter,
    CiamPagination,
    StatusBadge
  },
  data() {
    return {
      AlertStatus,
      email: '',
      data: [],
      loading: false,
      problem: null,
      totalElements: 0,
      totalPages: 0,
      sortField: this.$route.query.sortField || 'name',
      sortOrder: this.$route.query.sortOrder || 'asc',
      defaultSortOrder: 'asc',
      page: intOr(1, this.$route.query.page),
      size: intOr(15, this.$route.query.size)
    };
  },
  mounted() {
    if (this.email) {
      this.searchDeployments();
    }
  },
  methods: {
    formatDate,
    clearError() {
      this.problem = null;
    },

    onEmailDebounced(value) {
      if (value) {
        this.searchDeployments();
      }
    },

    onSearchClick() {
      this.searchDeployments();
    },

    async searchDeployments() {
      if (!this.email) {
        this.problem = {
          title: this.$t('error.validation.title'),
          detail: this.$t('backoffice.deployments.emailRequired')
        };
        return;
      }
      
      this.loading = true;
      this.problem = null;
      
      try {
        const response = await BackofficeService.searchDeploymentsByEmail(this.email, {
          page: this.page - 1,
          size: this.size,
          sort: `${this.sortField},${this.sortOrder}`
        });
        this.data = response || [];
        this.totalElements = response.totalElements || 0;
        this.totalPages = response.totalPages || 0;
      } catch (err) {
        console.error('Search error:', err);
        this.problem = {
          title: err.title || this.$t('error.generic.title'),
          detail: err.detail || this.$t('error.generic.description')
        };
        this.data = [];
        this.totalElements = 0;
      } finally {
        this.loading = false;
      }
    },

    onPageChange(page) {
      this.page = page;
      this.searchDeployments();
    },

    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.searchDeployments();
    }
  }
};
</script> 
