/**
 * Formats an ISO date string into a localized date-time string
 * @param {string} isoString - The ISO date string to format
 * @returns {string} Formatted date string or empty string if input is invalid
 */
export const formatDate = (isoString) => {
  if (!isoString) return '';
  return new Date(isoString).toLocaleString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZoneName: 'short'
  });
}; 