import { problemAwareHttp } from '@/http';
import Problem from '@/util/problem';

class BackofficeService {
  static async searchDeploymentsByEmail(email, pagination) {
    try {
      const response = await problemAwareHttp.get('/admin/deployments', {
        params: { 
          email,
          page: pagination.page,
          size: pagination.size,
          sort: pagination.sort
        }
      });
      return response.data;
    } catch (error) {
      throw Problem.fromError(error);
    }
  }
}

export default BackofficeService;

