<template>
    <div>
        <ciam-card>
            <ciam-card-header>
                <template v-slot:header><a :href="anchor">
                        <ciam-icon class="text-gray-400 h-4 w-4 cursor-pointer" name="fa-link"></ciam-icon>
                    </a>{{ $t('deployment.configuration.upgradeOnDemand.title') }}</template>
                <template v-slot:subtitle>{{ $t('deployment.configuration.upgradeOnDemand.subtitle') }}</template>
            </ciam-card-header>
            <ciam-card-content>
                <ciam-card-content-lines>
                    <ciam-card-content-line type="split-content-component">
                        <template v-slot:label> {{ $t(`deployment.configuration.upgradeOnDemand.title`) }} </template>

                        <template v-slot:content>
                            <div>
                                <div class="documentation"></div>
                                <div class="action">
                                    <ciam-button v-if="deploymentStatus === 'RUNNING' && isUpgradeAvailable()"
                                        @click="launchMaintenance()" class="secondary w-max">
                                        
                                        {{ $t(`deployment.configuration.upgradeOnDemand.button`, {
                                            version: upgrades.upgrades[0].target.split('keycloak-x:')[1] || "the latest minor version"
                                        })}}
                                        <template v-slot:right>
                                            <ciam-icon name="fa-upload"></ciam-icon>
                                        </template>
                                    </ciam-button>
                                    <!-- case upgrade available but status not running -->
                                    <ciam-button v-else-if="isUpgradeAvailable()" class="disabled animate-pulse"
                                        disabled="true">
                                        {{ $t(`deployment.configuration.upgradeOnDemand.button`, {
                                            version: upgrades.upgrades[0].target.split('keycloak-x:')[1] || "the latest minor version"
                                        })}}
                                        <template v-slot:right>
                                            <ciam-icon name="fa-upload"></ciam-icon>
                                        </template>
                                    </ciam-button>
                                    <!-- upgrade not available -->
                                    <ciam-button v-else class="disabled animate-pulse" :disabled=true>
                                        {{ $t(`deployment.configuration.upgradeOnDemand.buttonNotAvailable`) }}
                                        <template v-slot:right>
                                            <ciam-icon name="fa-upload"></ciam-icon>
                                        </template>
                                    </ciam-button>
                                </div>
                            </div>
                        </template>
                    </ciam-card-content-line>

                </ciam-card-content-lines>
            </ciam-card-content>
        </ciam-card>
    </div>
</template>

<script>
import CiamCard from '@/components/CiamCard';
import CiamCardHeader from '@/components/CiamCardHeader';
import CiamCardContent from '@/components/CiamCardContent';
import CiamCardContentLines from '@/components/CiamCardContentLines';
import DeploymentService from '@/pages/deployments/DeploymentService';
import { Notification } from 'vue-notifyjs';

export default {
    name: 'UpgradeOnDemand',
    components: {
        CiamCard,
        CiamCardHeader,
        CiamCardContentLines,
    },
    props: {
        deploymentId: {
            type: String,
        },
        anchor: {
            type: String,
            required: true
        },
        deploymentStatus: {
            type: String
        }  
    },
    data() {
        return {
            problem: null,
            upgrades: { upgrades: [] }
        };
    },
    methods: {
        async launchMaintenance() {
            if (!confirm('Are you sure you want to upgrade this deployment?')) {
                return;
            }
                DeploymentService.upgradeDeployment(this.deploymentId).then(() => {
                Notification.notify({
                    message: 'Upgrade has been scheduled...',
                    type: 'info',
                });
            },
                (err) => {
                    console.error(err);
                    Notification.notify({
                        message: 'Could not start upgrade: ' + err,
                        type: 'danger',
                    });
                }
            );
            
        },
        isUpgradeAvailable() {
            return this.upgrades.upgrades && this.upgrades.upgrades.length > 0;
        }
    },
    computed: {
    },
    mounted() { },
    async created() {
        this.upgrades = await DeploymentService.isUpgradeAvailable(this.deploymentId);
    }
};
</script>

<style scoped></style>