<template>
  <ciam-page :title-suffix="$t('pageTitles.backoffice')">
    <ciam-card>
      <ciam-card-header>
        <template v-slot:header>{{ $t('backoffice.header') }}</template>
        <template v-slot:subtitle>{{ $t('backoffice.subtitle') }}</template>
        <template v-slot:tabs>
          <ciam-tabs :tabs="tabs()"></ciam-tabs>
        </template>
      </ciam-card-header>
      <router-view></router-view>
    </ciam-card>
  </ciam-page>
</template>

<script>
import { routeNames } from '@/router';
import CiamCard from '@/components/CiamCard';
import CiamCardHeader from '@/components/CiamCardHeader';
import CiamTabs from '@/components/CiamTabs';

export default {
  name: 'Backoffice',
  components: {
    CiamCard,
    CiamCardHeader,
    CiamTabs
  },
  mounted() {
    // Redirect to deployments tab if we're at the root backoffice path
    if (this.$route.name === routeNames.Backoffice) {
      this.$router.push({ name: routeNames.BackofficeDeployments }).catch(() => {});
    }
  },
  methods: {
    tabs() {
      return [
        {
          icon: 'fa-server',
          id: 'deployments',
          name: this.$t('backoffice.tabs.deployments'),
          route: {
            name: routeNames.BackofficeDeployments
          },
          default: true
        }
      ];
    }
  }
};
</script>

<style scoped>
</style> 