import { render, staticRenderFns } from "./UpgradeOnDemand.vue?vue&type=template&id=e45a63da&scoped=true&"
import script from "./UpgradeOnDemand.vue?vue&type=script&lang=js&"
export * from "./UpgradeOnDemand.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e45a63da",
  null
  
)

export default component.exports