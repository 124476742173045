import { problemAwareHttp } from '@/http';
import { pathOr } from 'ramda';

export default class RegionService {
  /**
   * @typedef Region
   * @property {string} region_id
   * @property {string} provider_id
   * @property {string} name
   * @property {string} created_at
   * @property {string} updated_at
   */

  /**
   *
   * @param {number?} page
   * @param {number?} size
   * @return {Promise<Region[]>}
   */
  static async getAll({ page, size } = { page: undefined, size: undefined }) {
    const response = await problemAwareHttp.get('/regions', {
      params: {
        page,
        size,
      },
    });

    const returnedPageNumber = response.data.page.number;
    const totalPages = response.data.page.totalPages;

    let regions = pathOr([], 'data._embedded.regions'.split('.'), response);
    if (returnedPageNumber < totalPages) {
      regions = regions.concat(await this.getAll({ page: returnedPageNumber + 1 }));
    }
    return regions;
  }
}
