import Vue from 'vue'
import EventEmitter from 'event-emitter'
import App from './App.vue'
import './filters'
import router, { routeNames } from './router'
import Buefy from 'buefy'
import Notifications from 'vue-notifyjs'
import Loading from 'vue-loading-overlay'
import Keycloak from 'keycloak-js'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { path } from 'ramda'

// move this to ciam-icon once we get rid of Buefy
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// \
import 'vue-notifyjs/themes/default.scss'
import 'vue-loading-overlay/dist/vue-loading.css'
import variables from './assets/main.scss'
import { addResponseListener, setBearer, setLogout } from '@/http'
import UserService from '@/pages/UserService'
import VueLogger from 'vuejs-logger'
import { Promised } from 'vue-promised'
import { name as packageName, version as packageVersion } from '../package.json'
import i18n from './i18n'
import './assets/tailwind.css'
import CiamText from '@/components/CiamText'
import CiamCard from '@/components/CiamCard'
import CiamCardFooter from '@/components/CiamCardFooter'
import CiamCardHeader from '@/components/CiamCardHeader'
import CiamCardContent from '@/components/CiamCardContent'
import CiamCardContentLine from '@/components/CiamCardContentLine'
import CiamCardContentLines from '@/components/CiamCardContentLines'
import CiamButton from '@/components/CiamButton'
import CiamIcon from '@/components/CiamIcon'
import CiamLoader from '@/components/CiamLoader'
import CiamPage from '@/components/CiamPage.vue'
import {jwtDecode} from 'jwt-decode'

import { analyticsInit } from '@/tracking'
import KeycloakService from './pages/KeycloakService'

const developmentEnvironments = ['localhost', '127.0.0.1', 'staging-app.cloud-iam.com'];

require('./assets/main.scss');

// move this to ciam-icon once we get rid of Buefy
library.add(fas);
// \

Vue.config.productionTip = false;

Vue.use(VueLogger, {
  isEnabled: true,
  logLevel: developmentEnvironments.includes(location.hostname) ? 'debug' : 'error',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  showConsoleColors: true,
});

Vue.component('Promised', Promised);
Vue.component('vue-fontawesome', FontAwesomeIcon);

/**
 * Globally register cloud-iam components
 */
Vue.component('ciam-text', CiamText);
Vue.component('ciam-loader', CiamLoader);
Vue.component('ciam-button', CiamButton);
Vue.component('ciam-icon', CiamIcon);
Vue.component('ciam-card', CiamCard);
Vue.component('ciam-card-header', CiamCardHeader);
Vue.component('ciam-card-content', CiamCardContent);
Vue.component('ciam-card-content-lines', CiamCardContentLines);
Vue.component('ciam-card-content-line', CiamCardContentLine);
Vue.component('ciam-card-footer', CiamCardFooter);
Vue.component('ciam-page', CiamPage);

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
});

Vue.use(Notifications, {
  type: 'primary',
  horizontalAlign: 'right',
  verticalAlign: 'top',
  timeout: 5000,
}); // info | warning | danger | success | primary
Vue.use(Loading, {
  color: variables.primary,
  container: null,
  canCancel: true,
});

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: location.hostname.includes('netlify.app') ? '{build}.netlify.app' : location.hostname,
  release: `${packageName}@${packageVersion}`,
  enabled: false,
  debug: developmentEnvironments.includes(location.hostname),
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  tracingOptions: {
    trackComponents: true,
  },
});

addResponseListener((error /*, success*/) => {
  if (error) {
    Sentry.addBreadcrumb({
      category: 'xhr',
      message: 'Error response: ' + error.message,
      level: Sentry.Severity.Error,
      data: path(['response', 'data'], error),
    });
  }
});

Vue.config.errorHandler = function (err, vm, info) {
  if (developmentEnvironments.includes(location.hostname)) {
    // eslint-disable-next-line no-console
    console.error(err, vm, info);
  }
  Sentry.captureException(err);
};

router.afterEach((to /*, from*/) => {
  try {
    // we must use a try-catch here because sentry might have been blocked
    if (to && to.name) {
      Sentry.configureScope((scope) => scope.setTransactionName(to.name));
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
});

async function verifyToken(token, tokenParsed) {
  const iamUrl = getCurrentIam();
  return await getIamIssuer(iamUrl, tokenParsed.iss) && await getTokenSignature(iamUrl, getTokenHeader(token));
}

const getTokenHeader = (token) => {
  return jwtDecode(token, { header: true });
};

async function getTokenSignature(iamUrl, tokenHeader) {
  const keys = await KeycloakService.getSignature(iamUrl);
  return tokenHeader.kid == keys.kid;
}

async function getIamIssuer(iamUrl, tokenIss) {
  const iss = await KeycloakService.getOpenidConfiguration(iamUrl);
  return iss == tokenIss;
}

function getCurrentIam() {  
  return location.host == "app.cloud-iam.com" ? "iam" : "staging-iam";
}

async function keycloakInit() {
  const keycloak = new Keycloak({
    url: location.hostname.includes('cloud-iam.com')
      ? /* in staging & production, the iam is in the subdomain*/ location.protocol +
      '//' +
      location.hostname.replace('app', 'iam') +
      '/auth'
      : process.env.VUE_APP_KEYCLOAK_URL,
    realm: process.env.VUE_APP_KEYCLOAK_REALM || 'cloud-iam',
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID || 'cloud-iam-ui',
    enableLogging: false
  });
  Vue.prototype.$keycloak = keycloak;
  try {
    let auth = await keycloak.init({
      onLoad: 'login-required',
      redirectUri: window.location.href,
      checkLoginIframe: false,
      pkceMethod: 'S256',
      enableLogging: developmentEnvironments.includes(location.hostname),
    });

    await keycloak.loadUserInfo();
    const isTokenValid = await verifyToken(keycloak.token, keycloak.tokenParsed);

    if (!auth || !isTokenValid) {
      window.location.reload();
    } else {
      Vue.$log.debug('Authenticated');
      setBearer(keycloak.token);
      setLogout(() => keycloak.logout());
      UserService.login({
        id: keycloak.tokenParsed.sub,
        firstName: keycloak.tokenParsed.given_name,
        lastName: keycloak.tokenParsed.family_name,
        email: keycloak.tokenParsed.email,
        lastConnectedAt: keycloak.tokenParsed.ciam_last_login,
        visitorId: keycloak.userInfo.visitorId,
        subscribeNl: keycloak.userInfo.subscribeNl
      })
        .then(() => {
          // extend Vue with routesNames
          Vue.prototype.$routes = routeNames;

          new Vue({
            render: (h) => h(App, { props: { keycloak: keycloak } }),
            i18n,
            router,
          }).$mount('#app');
        })
        .catch((problem) => {
          console.log('could not communicate with server', problem);
          window.location = '/init-error.html';
        });
    }
  } catch (err) {
    Vue.$log.error('Failed to initialize', err);
  }

  keycloak.onTokenExpired = () => {
    Vue.$log.info('onTokenExpired Token expired');
    keycloak
      .updateToken(30)
      .then((refreshed) => {
        if (refreshed) {
          Vue.$log.info('onTokenExpired Token refreshed ' + refreshed);
          setBearer(keycloak.token);
        } else {
          Vue.$log.warn(
            'onTokenExpired Token not refreshed, valid for ' +
            Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) +
            ' seconds'
          );
        }
      })
      .catch(() => {
        Vue.$log.error('onTokenExpired Failed to refresh token');
        keycloak.login();
      });
  };
}

keycloakInit();

export const bus = new EventEmitter();

analyticsInit();
